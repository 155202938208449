<template>
  <b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
      <!-- <feather-icon
        badge="6"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      /> -->
      <u-icon v-if="unreadNot > 0" :badge="unreadNot" badge-classes="bg-danger" class="text-body" icon="fi-rr-bell"
        size="20" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto" style="font-size: 20px;font-weight: 700;">
          Notifications
        </h4>
        <b-badge pill variant="danger">
          {{ unreadNot }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar v-once :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area" tagname="li" v-if="notifications.length > 0">
      <!-- Account Notification -->
      <b-link v-for="notification in getNoti" :key="notification.id">
        <b-media>
          <template #aside>
            <b-avatar size="32" :src="notification.avatar" :text="notification.avatar"
              :variant="resolveNotifVariant(notification.type)" />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <!-- <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li> -->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import firebase from "firebase/app";
import { ref, computed, onMounted } from "@vue/composition-api";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  watch: {
    notifications() {
      this.getNoti
    }
  },
  computed: {
    getNoti() {
      console.log(this.notifications.length)
      return this.notifications
    }
  },
  methods:{
    changeNotifTime(){}
  },
  created() {
    // console.log(this)
    if (this.$acl.get[0] == 'admin') {
      firebase.auth().onAuthStateChanged((user) => {
        if (user != null) {
          var uid = user.uid
          // console.log(uid);
          if (uid) {
            var collegeCode = this.$store.state.mainUserCodes.CollegeCode
            let ref = firebase.firestore().collection("CollegeCode").doc(collegeCode)
            let notifRef = ref.collection('NotificationService')
            var lastNotifTime = null;
            ref.get().then((doc) => {
              lastNotifTime = doc.data().lastNotifRead[`${uid}`] || null
              // console.log(lastNotifTime)
            }).then(() => {
              if (lastNotifTime) {
                let lastTimestamp = lastNotifTime.toDate()
                // console.log(lastTimestamp)
                if (lastTimestamp) {
                  notifRef.where('timestamp', '>=', lastTimestamp).orderBy("timestamp", 'desc').get().then((notifs) => {
                    this.unreadNot = notifs.size
                    notifs.forEach((notif) => {
                      var obj = notif.data()
                      obj.id = notif.id
                      this.notifications.push(obj)
                    })
                  }).then(() => {
                    // console.log(this.notifications.length)
                  })
                }
              }
            })
            // ref.where('isreadBy','not-in')
          }
        }
      })
    }
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = ref([])
    const unreadNot = ref(0);
    const resolveNotifVariant = (val) => {
      if (val == 'job') return 'light-danger'
      else if (val == 'placed') return 'light-success'
      else if (val == 'recruiter') return 'light-info'
      else return 'light-primary'
    }
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      perfectScrollbarSettings,
      resolveNotifVariant,
      unreadNot
    }
  },
}
</script>

<style lang="scss">
.badge-pill {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
  line-height: 1.2;
}
</style>
