export default [
  {
    title: 'Home',
    icon: 'fi-rr-home',
    route: 'home-dashboard',
    meta:'admin'

    // tag: '2',
    // tagVariant: 'light-warning',
    // children: [
    //   {
    //     title: 'eCommerce',
    //     route: 'dashboard-ecommerce',
    //   },
    //   {
    //     title: 'Analytics',
    //     route: 'dashboard-analytics',
    //   },
    // ],
  },
  {
    title: 'Home',
    icon: 'fi-rr-home',
    route: 'home-recruiter',
    meta:'recruiter'

    // tag: '2',
    // tagVariant: 'light-warning',
    // children: [
    //   {
    //     title: 'eCommerce',
    //     route: 'dashboard-ecommerce',
    //   },
    //   {
    //     title: 'Analytics',
    //     route: 'dashboard-analytics',
    //   },
    // ],
  },
]
