<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bold mb-0" style="color: #043c57;">
          {{ userData.username || userData.displayName }}
        </p>
        <span class="user-status">{{ userData.CollegeName }}</span>
      </div>
      <b-avatar id="nav-avatar" size="40" square :src="userData.avatar || userData.profilephoto" variant="transparent"
        badge class="badge-minimal text-secondary avatar-border-2" badge-variant="success"
        style="border-radius: 16px !important;">
        <feather-icon v-if="!userData.username" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="MailIcon" class="mr-50" />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
      <span>Chat</span>
    </b-dropdown-item>

    <b-dropdown-divider /> -->

    <b-dropdown-item
      :to="$acl.get == 'recruiter' ? { name: 'pages-general-settings-recruiter' } : { name: 'pages-profile-settings' }"
      link-class="d-flex align-items-center">
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Settings</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
      <span>Pricing</span>
    </b-dropdown-item> -->
    <b-dropdown-item :to="{ name: 'pages-faq' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>Support</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import { ref, computed, onMounted } from "@vue/composition-api";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  setup() {
    const userData = ref(JSON.parse(localStorage.getItem("userInfo")));
    const getName = computed(() => {
      userData.value = JSON.parse(localStorage.getItem("userInfo"));
    });
    onMounted(() =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user != null) {
          getName;
        } else {
          console.log("no current user");
        }
      })
    );
    firebase.auth().onAuthStateChanged((user) => {
      if (user != null) {
        getName;
      } else {
        console.log("no current user");
      }
    });
    getName;
    return { userData, getName, avatarText };
  },
  created() {
    // this.getName;
    // console.log(JSON.parse(localStorage.getItem("userInfo")))
    this.$store.watch(
      (state) => {
        return this.$store.state.auth.AppActiveUser;
      },
      (newVal, oldVal) => {
        this.userData.avatar = newVal?.profilephoto ?? newVal?.avatar ?? ''
        this.userData.username = newVal?.username ?? newVal?.displayName
      },
      { deep: true }
    );
  },
  methods: {
    logout() {
      const firebaseCurrentUser = firebase.auth().currentUser;

      if (firebaseCurrentUser) {
        firebase
          .auth()
          .signOut()
          .then(() => {
           

            // Remove userData from localStorage
            localStorage.removeItem("userData");
            localStorage.removeItem("userInfo");
            // console.log(this.$store);
            this.$store.dispatch("updateMainUserCodes", {
              user_id: null,
              RecruiterCode: null
            });
            this.$store.dispatch("auth/updateUserCodes", {
              user_id: null,
              RecruiterCode: null
            }, { root: true });

            // Reset ability
            this.$ability.update(initialAbility);
            this.$acl.change("public")

            // Redirect to login page
            this.$router.push({ name: "auth-login" });
          });
      }
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    },
  },
};
</script>
<style lang="scss">
#nav-avatar {
  .b-avatar {
    border-radius: 16px !important;
  }
}
</style>
