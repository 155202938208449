export default [
  {
    header: 'Dashboards',
    icon: 'HomeIcon',
    title: 'Dashboard',
    route:'dashboard-analytics'
    // children: [
    //   {
    //     title: 'eCommerce',
    //     route: 'dashboard-ecommerce',
    //     icon: 'ShoppingCartIcon',
    //   },
    //   {
    //     title: 'Analytics',
    //     route: 'dashboard-analytics',
    //     icon: 'ActivityIcon',
    //   },
    // ],
  },
]
