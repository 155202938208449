<template>
  <div class="main-menu menu-fixed menu-accordion " :class="[
    {
      expanded:
        !isVerticalMenuCollapsed ||
        (isVerticalMenuCollapsed && isMouseHovered),
    },
    skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
  ]" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded mb-0 mt-1">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto ">
            <b-media vertical-align="start" class="">
              <template #aside>
                <b-avatar size="48" square :src="CollegeLogo" :text="avatarText(CollegeName)" variant="transparent"
                  class="cursor-pointer badge-minimal avatar-border-2 text-dark" />
              </template>
              <!-- :to="{ name: 'apps-users-view', params: { id: CollegeCode } }" -->

              <h2 class=" d-block mb-0"
                style=" font-size: 14px; font-weight: 700; max-height: 4.8em;overflow: hidden;text-overflow: ellipsis;">
                {{ CollegeName }}
              </h2>
              <!-- text-nowrap -->
              <small class=" "> {{ CollegeLocation }}</small>
            </b-media>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <u-icon :icon="collapseTogglerIconFeather" size="20" class="d-block d-xl-none collapse-toggle-icon"
                @click="toggleVerticalMenuActive"></u-icon>
              <!-- <feather-icon icon="XIcon" size="20" class="d-block d-xl-none collapse-toggle-icon" @click="toggleVerticalMenuActive" /> -->
              <!--  <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              /> -->
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />
    <!-- college logo and customization -->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="main-menu-content scroll-area mt-1" tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      ">
      <vertical-nav-menu-items :items="filterednavMenuItems" class="navigation navigation-main" />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->

    <!--copyright text-->
    <div class=" w-100" style="position: fixed; bottom:20px;">
      <b-link class=" d-flex align-items-center justify-content-center align-middle" to="/">
        <span class="brand-logo">
          <b-img :src="appNameSvg" alt="logo" />
        </span>
      </b-link>
    </div>
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg, BMedia, BAvatar } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import firebase from "firebase/app";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BMedia,
    BAvatar,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      CollegeName: store.state.mainUserCodes.CollegeName ?? '',
      CollegeLogo: store.state.mainUserCodes.CollegeLogo ?? '',
      CollegeCode: store.state.mainUserCodes.CollegeCode,
      CollegeLocation: store.state.mainUserCodes.CollegeLocation ?? ''
    }
  },

  watch: {
    $store() {
      console.log("store is updated");
    },
  },
  created() {
    this.getCollegeData()
    // this.$store.watch(
    //   (state) => {
    //     return this.$store.state.mainUserCodes;
    //   },
    //   (newVal, oldVal) => {
    //     this.getCollegeData()

    //   },
    //   { deep: true }
    // );

  },
  methods: {
    getCollegeData() {
      if (store.state.mainUserCodes.CollegeName == undefined) {
        firebase.firestore().collection("CollegeCode").doc(store.state.mainUserCodes.CollegeCode).get().then((doc) => {
          if (doc.exists) {
            this.CollegeName = doc.data().CollegeName
            this.CollegeLogo = doc.data().logo
            this.CollegeLocation = doc.data().CampusLocation
            store.dispatch("updateMainUserCodes", {
              CollegeName: doc.data().CollegeName,
              CollegeLocation: doc.data().CampusLocation,
              CollegeLogo: doc.data().logo,
              // user_id: user.uid
            });
          }
        }).then(() => {
          if (this.$acl.get == 'recruiter') {
            var uid = firebase.auth().currentUser.uid
            firebase.firestore().collection("RecruiterId").doc(uid).get().then((rec) => {
              var recruiterCode = rec.data().RecruiterCode ?? null
              store.dispatch("updateMainUserCodes", {
                RecruiterCode: recruiterCode
                // user_id: user.uid
              });
            })
          }
        })
      }
    }
  },

  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "fi-rr-circle" : "fi-rr-record-vinyl"
    );

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    const appNameSvg = require('@/assets/images/logo/flairboat_logo.svg')
    let filterednavMenuItems = ref([])
    let userInfo =
      JSON.parse(localStorage.getItem("userInfo"))
    // console.log('this is userinfo in vertical nav menu')
    // console.log(userInfo)
    filterednavMenuItems.value = navMenuItems.filter((item) => item.meta == userInfo.role)
    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      filterednavMenuItems,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appNameSvg,

      //college details
      // CollegeName,
      // CollegeLogo,
      // CollegeCode,
      // CollegeLocation,

      //filter
      avatarText,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
