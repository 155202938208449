export default [
  // {
  //   header: 'Apps & Pages',
  // },
  // {
  //   title: 'Email',
  //   route: 'apps-email',
  //   icon: 'MailIcon',
  // },
  {
    title: 'Chat',
    route: 'apps-chat',
    icon: 'fi-rr-comment-alt',
    meta:'admin'
  },
  {
    title: 'Jobs',
    icon: 'fi-rr-briefcase',
    route: 'all-jobs',
    meta:'admin'
  },
  {
    title: 'Students',
    icon: 'fi-rr-user',
    route: 'all-students-list',
    meta:'admin'
  },
  {
    title: 'Recruiters',
    icon: 'fi-rr-building',
    // route: 'apps-users-list',
    route:'all-recruiters-list',
    meta:'admin'
  },
  {
    title: 'Notices',
    icon: 'fi-rr-megaphone',
    route: 'all-notices',
    meta:'admin'
  },
  {
    title: 'Placement Report',
    icon: 'fi-rr-chart-histogram',
    route: 'placement-report',
    meta:'admin'
  },

  //*===============================---*
  ///*------recruiter routes ----------*
  //*===============================---*
  {
    title: 'Chat',
    route: 'apps-chat-recruiter',
    icon: 'fi-rr-comment-alt',
    meta:'recruiter'
  },
  {
    title: 'Interview Schedule',
    route: 'all-interviews-list',
    icon: 'fi-rr-calendar-clock',
    meta:'recruiter'
  },
  {
  title: 'Candidates',
    icon: 'fi-rr-users-alt',
    meta:'recruiter',
    route: 'all-candidates-list',
    
  }
]
