var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion ",class:[
  {
    expanded:
      !_vm.isVerticalMenuCollapsed ||
      (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
  },
  _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded mb-0 mt-1"},[_vm._t("header",[_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto "},[_c('b-media',{attrs:{"vertical-align":"start"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{staticClass:"cursor-pointer badge-minimal avatar-border-2 text-dark",attrs:{"size":"48","square":"","src":_vm.CollegeLogo,"text":_vm.avatarText(_vm.CollegeName),"variant":"transparent"}})]},proxy:true}])},[_c('h2',{staticClass:" d-block mb-0",staticStyle:{"font-size":"14px","font-weight":"700","max-height":"4.8em","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.CollegeName)+" ")]),_c('small',{staticClass:" "},[_vm._v(" "+_vm._s(_vm.CollegeLocation))])])],1),_c('li',{staticClass:"nav-item nav-toggle"},[_c('b-link',{staticClass:"nav-link modern-nav-toggle"},[_c('u-icon',{staticClass:"d-block d-xl-none collapse-toggle-icon",attrs:{"icon":_vm.collapseTogglerIconFeather,"size":"20"},on:{"click":_vm.toggleVerticalMenuActive}})],1)],1)])],{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area mt-1",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }}},[_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main",attrs:{"items":_vm.filterednavMenuItems}})],1),_c('div',{staticClass:" w-100",staticStyle:{"position":"fixed","bottom":"20px"}},[_c('b-link',{staticClass:" d-flex align-items-center justify-content-center align-middle",attrs:{"to":"/"}},[_c('span',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"src":_vm.appNameSvg,"alt":"logo"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }